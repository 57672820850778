<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @fetch-data="fetchUsers"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="paginationData.pageSize"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageSizeOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="paginationData.query"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <!--        <users-list-filters-->
        <!--          :status-options="statusOptions"-->
        <!--          :status-filter="paginationData.statusFilter"-->
        <!--          :plan-options="planOptions"-->
        <!--          :plan-filter="paginationData.planFilter"-->
        <!--          :role-options="roleOptions"-->
        <!--          :role-filter="paginationData.roleFilter"-->
        <!--          @update:statusFilter="paginationData.statusFilter = $event"-->
        <!--          @update:planFilter="paginationData.planFilter = $event"-->
        <!--          @update:roleFilter="paginationData.roleFilter = $event"-->
        <!--        />-->
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="users"
        responsive
        :fields="usersTableColumns"
        primary-key="id"
        :sort-by.sync="paginationData.sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="paginationData.sortDesc"
      >
        <!-- Column: User -->
        <template #cell(fullName)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="(data.item.creators!==null && data.item.creators!==undefined) ? data.item.creators.portraitPhoto : ''"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(
                  data.item.userRoles.role.split('_')[1]
                )}`"
                :to="{ name: 'users', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'view-user', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(userRoles)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="
                resolveUserRoleIcon(data.item.userRoles.role.split('_')[1])
              "
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.userRoles.role.split("_")[1]
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(active)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(
              data.item.active ? 'Enabled' : 'Disabled'
            )}`"
            class="text-capitalize"
          >
            {{ data.item.active ? "Enabled" : "Disabled" }}
          </b-badge>
        </template>
        <template #cell(inviteAcceptedAt)="data">
          <b-badge
            pill
            :variant="`light-${
              data.item.inviteAcceptedAt ? 'success' : 'danger'
            }`"
            class="text-capitalize"
          >
            {{ data.item.inviteAcceptedAt ? "Accepted" : "Pending" }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="toggleUserStatus(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span
                class="align-middle ml-50"
              >{{ data.item.active ? "Disable" : "Enable" }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteAccount(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ metaDataOfTable.from }} to {{ metaDataOfTable.to }} of
              {{ metaDataOfTable.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              :value="paginationData.page + 1"
              :total-rows="totalRecords"
              :per-page="paginationData.pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="pageChanged"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import {
  getPaginatedUsers,
  roleOptions,
  resolveUserRoleVariant,
  resolveUserRoleIcon,
  resolveUserStatusVariant,
  statusOptions,
  planOptions,
  toggleUser,
  deleteUser,
} from '@/api/users'
import UserListAddNew from '../components/Users/UserList/UserListAddNew.vue'
// import UsersListFilters from '../components/Users/UsersListFilters.vue'

export default {
  components: {
    // UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup(props, context) {
    const swal = context.root.$swal
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)
    const refUserListTable = ref(null)
    const isAddNewUserSidebarActive = ref(false)
    const usersTableColumns = ref([
      { key: 'fullName', sortable: true },
      { key: 'email', sortable: true },
      {
        key: 'contactNo',
        sortable: true,
      },
      {
        key: 'userRoles',
        label: 'Role',
        formatter: value => value.role,
      },
      {
        key: 'inviteAcceptedAt',
        label: 'Invite status',
        formatter: value => (value ? 'Yes' : 'No'),
      },
      {
        key: 'active',
        label: 'Status',
      },
      { key: 'actions' },
    ])
    const paginationData = ref({
      query: '',
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      page: 0,
      pageSize: 10,
      sortBy: 'id',
      sortDesc: true,
    })
    const pageSizeOptions = ref([5, 10, 25, 50, 100])
    const totalRecords = ref(0)
    const users = ref([])

    // Methods
    const fetchUsers = async () => {
      const { data } = await getPaginatedUsers(paginationData.value)
      totalRecords.value = data.totalElements
      users.value = data.content
    }
    const pageChanged = value => {
      paginationData.value.page = value - 1
    }
    const showLoading = () => {
      swal.fire({
        title: 'Please Wait! We are processing the data.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          swal.showLoading()
        },
      })
    }
    const deleteAccount = async id => {
      try {
        showLoading()
        const resp = await deleteUser(id)
        if (resp.status === 200) {
          await fetchUsers()
          swal('Success', 'User Has Been Deleted Successfully', 'success')
        }
      } catch (err) {
        swal('Error', 'Something Went Wrong', 'error')
      }
    }
    const toggleUserStatus = async id => {
      try {
        showLoading()
        const { data } = await toggleUser(id)
        if (data) {
          await fetchUsers()
          swal('Success', '', 'success')
        }
      } catch (err) {
        swal('Error', 'Something Went Wrong', 'error')
      }
    }
    // End Methods

    // Computed
    const metaDataOfTable = computed(() => {
      const localItemsCount = refUserListTable.value
        ? refUserListTable.value.localItems.length
        : 0
      return {
        from:
          paginationData.value.pageSize * (paginationData.value.page)
          + (localItemsCount ? 1 : 0),
        to:
          paginationData.value.pageSize * (paginationData.value.page)
          + localItemsCount,
        of: totalRecords.value,
      }
    })
    // End Computed

    // Watch
    watch(paginationData.value, fetchUsers)
    // End watch

    // Mounted
    onMounted(() => {
      fetchUsers()
    })
    // End Mounted

    return {
      // Vars
      isAddNewUserSidebarActive,
      users,
      totalRecords,
      paginationData,
      usersTableColumns,
      pageSizeOptions,
      metaDataOfTable,
      // Methods
      fetchUsers,
      pageChanged,
      toggleUserStatus,
      deleteAccount,
      // Ref
      refUserListTable,
      // Filter
      avatarText,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      roleOptions,
      statusOptions,
      planOptions,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
