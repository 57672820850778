<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <b-form-group
            label="Full Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.fullName"
              autofocus
              trim
              placeholder="John Doe"
            />
          </b-form-group>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- contact -->
          <b-form-group
            label="Contact"
            label-for="contact"
          >
            <b-form-input
              id="contact"
              v-model="userData.contactNo"
              trim
            />
          </b-form-group>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.userRoles.id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider
            name="Country"
            rules="required"
          >
            <b-form-group
              label="Country"
              label-for="country"
            >
              <v-select
                v-model="userData.country"
                :options="allCountries"
                label="name"
                :clearable="false"
                input-id="country"
              />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onMounted, computed } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { fetchAllCountries, addNewUser } from '@/api/users'
// import countries from '@/@fake-db/data/other/countries'
// import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      // countries,
    }
  },
  setup(props, context) {
    const swal = context.root.$swal
    const blankUserData = {
      fullName: '',
      email: '',
      userRoles: {
        id: null,
      },
      country: {
        id: null,
      },
      contactNo: '',
    }
    const allCountries = ref([])
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const fetchCountries = async () => {
      const { data } = await fetchAllCountries()
      allCountries.value = data
    }
    const showLoading = () => {
      swal.fire({
        title: 'Please Wait!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          swal.showLoading()
        },
      })
    }
    const onSubmit = async () => {
      showLoading()
      try {
        const resp = await addNewUser(userData.value)
        if (resp.data) {
          swal('Invite Sent', 'Email Invite Has Been Sent Successfully', 'success')
          context.emit('fetch-data')
          context.emit('update:is-add-new-user-sidebar-active', false)
        } else {
          swal('User Already Exist', 'User With This Email Already Exist', 'error')
          context.emit('update:is-add-new-user-sidebar-active', false)
        }
      } catch (err) {
        swal('Error', 'Something Went Wrong.', 'error')
      }
    }
    // Mounted
    onMounted(() => {
      fetchCountries()
    })
    const allCountriesName = computed(() => allCountries.value.filter(country => country.name))

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetUserData)

    return {
      allCountries,
      allCountriesName,
      userData,
      onSubmit,
      fetchCountries,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
